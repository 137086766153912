<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Lending component
 */
export default {
  page: {
    title: "Forex Transfer List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    simplebar,
  },
  data() {
    return {
      title: "Forex Transfer List",
      items: [
        {
          text: "Forex Transfer List",
          href: "#",
        },
        {
          text: "Forex Transfer",
          active: true,
        },
      ],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "id",
          value: "#",
          sortable: false,
        },
        {
          key: "broker_name",
          value: "broker_name",
          sortable: false,
        },
        {
          key: "user_name",
          value: "user_name",
          sortable: false,
        },
        {
          key: "account_number",
          value: "account_number",
          sortable: false,
        },
        {
          key: "holder_name",
          value: "holder_name",
          sortable: false,
        },
        {
          key: "currency",
          value: "currency",
          sortable: false,
        },
        {
          key: "amount",
          value: "amount",
          sortable: false,
        },
        {
          key: "transfer_status",
          value: "transfer_status",
          sortable: false,
        },
        {
          key: "status",
          value: "status",
          sortable: false,
        },
        {
          key: "payment",
          value: "payment",
          sortable: false,
        },
        {
          key: "created_at",
          value: "created_at",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async updatestatus(e, url) {
      var statusvalue = url == "admin_verify" ? e.transfer_status : e.status;
      var result = await ApiClass.updateFormRequest(
        "admin/transfer_forex/" + url + "/" + e.id,
        true,
        { status: statusvalue }
      );
      if (result.data.status_code == "1") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        if (url == "update_status") {
          e.status = !e.status;
        }

        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    },
    async myProvider(ctx, callback) {
      this.loading = !this.loading;
      await ApiClass.getRequest(
        "admin/transfer_forex/get?per_page=" +
          ctx.perPage +
          "&page=" +
          ctx.currentPage,
        true
      )
        .then((res) => {
          if (res.data.status_code == 1) {
            this.loading = !this.loading;
            const items = res.data.data ? res.data.data.data : [];
            this.currentPage = res.data.data ? res.data.data.current_page : "";
            this.totalRows = res.data.data ? res.data.data.total : "";

            callback(items);
          }
        })
        .bind(this);
    },
    onclose() {
      this.$root.$emit("bv::hide::modal", "modal-center");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row title-row">
              <div class="col-xl-6 col-lg-6 col-md-6">
                <h4 class="card-title mb-4">Forex Transfer List</h4>
              </div>
            </div>

            <simplebar style="max-height: 600px">
              <div class="table-responsive">
                <b-table
                  :items="myProvider"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :busy="loading"
                  ref="table"
                  show-empty
                >
                  <template #empty>
                    <div class="d-flex justify-content-center mb-3">
                      No Record Found
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <!-- <strong>Loading...</strong> -->
                    </div>
                  </template>

                  <template v-slot:cell(id)="data">
                    <div v-if="data.item.id">
                      {{ data.item.id }}
                    </div>
                  </template>

                  <template v-slot:cell(broker_name)="data">
                    <div v-if="data.item.broker">
                      {{ data.item.broker.name }}
                    </div>
                  </template>

                  <template v-slot:cell(user_name)="data">
                    <div v-if="data.item.user">
                      {{ data.item.user.name }}
                    </div>
                  </template>

                  <template v-slot:cell(account_number)="data">
                    <div v-if="data.item.account_number">
                      {{ data.item.account_number }}
                    </div>
                  </template>

                  <template v-slot:cell(holder_name)="data">
                    <div v-if="data.item.holder_name">
                      {{ data.item.holder_name }}
                    </div>
                  </template>

                  <template v-slot:cell(amount)="data">
                    <div v-if="data.item.amount">
                      {{ data.item.amount }}
                    </div>
                  </template>

                  <template v-slot:cell(transfer_status)="data">
                    <select
                      v-model="data.item.transfer_status"
                      @change="updatestatus(data.item, 'admin_verify')"
                    >
                      <option value="0">Pending</option>
                      <option value="1">Rejected</option>
                      <option value="2">Approved</option>
                    </select>
                  </template>

                  <!-- Status section -->
                  <template v-slot:cell(status)="data">
                    <div class="form-check form-switch form-switch-md mb-3">
                      <input
                        v-model="data.item.status"
                        @change="updatestatus(data.item, 'update_status')"
                        class="form-check-input"
                        type="checkbox"
                        id="SwitchCheckSizemd"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(payment)="data">
                    <div v-if="data.item.status == 1">
                      <span class="text-success"
                        ><strong>Completed</strong></span
                      >
                    </div>
                    <div v-else>
                      <span class="text-danger"
                        ><strong>Still Pending</strong></span
                      >
                    </div>
                  </template>

                  <template v-slot:cell(created_at)="data">
                    <div v-if="data.item.created_at">
                      {{ new Date(data.item.created_at).toLocaleDateString() }}
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="row m-0">
                <div class="col p-0">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </simplebar>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

